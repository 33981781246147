import substract from "../Images/Subtract.png";
import profile_image from "../Images/profile.png";
import Linkedin_icon from "../Images/icon-sosmed/linkedin.svg";
import Tiktok_icon from "../Images/icon-sosmed/Tiktok.svg";
import whatsapp_icon from "../Images/icon-sosmed/whatsapp.svg";
import github_icon from "../Images/icon-sosmed/Github.svg";
import instagram_icon from "../Images/icon-sosmed/Instagram.svg";
import Figma_logo from "../Images/skill_icons/Figma.svg";
import InertiaJS_logo from "../Images/skill_icons/InertiaJS.svg";
import Laravel_logo from "../Images/skill_icons/Laravel.svg";
import Next_logo from "../Images/skill_icons/Next.svg";
import React_logo from "../Images/skill_icons/React.svg";
import Tailwinds_logo from "../Images/skill_icons/Tailwinds.svg";
import WordpressLogo from "../Images/skill_icons/Wordpress.svg";
import gsap_logo from "../Images/skill_icons/gsap.svg";
import three_logo from "../Images/skill_icons/Threejs.svg";
import Line_logo from "../Images/Line.svg";
import item_logo from "../Images/item.png";
import project_image from "../Images/Porto/project.png";
import project_image2 from "../Images/Porto/project2.png";
import project_image3 from "../Images/Porto/project3.png";
import project_image4 from "../Images/Porto/project4.png";
import project_image5 from "../Images/Porto/project5.png";
import project_image6 from "../Images/Porto/project6.png";
import reactporto from "../Images/icon/react.png";
import tailwindsporto from "../Images/icon/tailwinds.png";
import javascriptporto from "../Images/icon/javascript.png";
import laravelporto from "../Images/icon/laravel.svg";
import ability_logo from "../Images/icon/Design.png";
import ability_logo2 from "../Images/icon/Dev.png";
import ability_logo3 from "../Images/icon/Video.png";

//  ================= export =====================
export const Substract = substract;
export const profile = profile_image;
export const Linkedin = Linkedin_icon;
export const whatsapp = whatsapp_icon;
export const Tiktok = Tiktok_icon;
export const github = github_icon;
export const Instagram = instagram_icon;
export const figma = Figma_logo;
export const Inertia = InertiaJS_logo;
export const Laravel = Laravel_logo;
export const Next = Next_logo;
export const gsap = gsap_logo;
export const three = three_logo;
export const ReactSkill = React_logo;
export const tailwinds = Tailwinds_logo;
export const wordpress = WordpressLogo;
export const Line = Line_logo;
export const item = item_logo;
export const project1 = project_image;
export const project2 = project_image2;
export const project3 = project_image3;
export const project4 = project_image4;
export const project5 = project_image5;
export const project6 = project_image6;
export const reactPorto = reactporto;
export const tailwindsPorto = tailwindsporto;
export const javascriptPorto = javascriptporto;
export const laravelPorto = laravelporto;
export const Ability1 = ability_logo;
export const Ability2 = ability_logo2;
export const Ability3 = ability_logo3;
